.tab-detail-1{
    height: -10px;
    border-radius: 3px;
    background-color: #427D9D;
}

.icon-md{
    font-size: 1.8rem;
}

.number-detail-1 {
    font-size: 1.2rem;
    font-weight: bold;
}

.tab-header {
    color: black;
    background-color: aliceblue;
    height: 40px;
    margin-top: 30px;
    border-radius: 3px;
    padding: 10px auto;
}

.icon-sm {
    font-size: 1.2rem;
}

p {
    margin: 0;
}

.card_detail {
    margin: 0;
    padding: 0;
    width: 320px;
}

.card_detail-head {
    background-color: #164863;
}
.card_detail-body {
    background-color: #427D9D;
}

.input-group-text {
    background-color: yellow !important;
}

.footer {
    background-color: #427D9D;
    height: 60px;
}