.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  font-size: medium;
  color: white;
}

.App-center {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.App-link {
  color: #61dafb;
}

.btn-close {
  filter: invert(1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* ขนาดสูงสุดที่ต้องการแสดง */
  cursor: pointer;
  user-select: none; /* ไม่อนุญาตให้เลือกข้อความ */
}

ol.breadcrumb {
  margin-bottom: 0rem !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #282c3490;
  position: fixed;
}

.loading {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-close {
  filter: brightness(0.5);
}
@media (max-width:400px) {
  .datepickerform {
    zoom: 60%;
  }
}
