.btn_icon {
    height: 31px;
}

.btn_dropdown {
    height: 30px;
}

.btn_dropdown>button {
    font-size: 0.8rem;
    border-radius: 0px;
}