.income_cal_wrapper {
    height: 100%;
    margin-top: 10px;
    border-radius: 3px;
    background-color: #427D9D;
    padding-top: 15px;
}

.income_cal_header {
    align-items: center;
}

.filter-table {
    justify-content: end;
}

.filter-table {
    height: 38px;
}

.react-datepicker__input-container {
    height: 100%;
}

.react-datepicker__input-container>input {
    height: 100%;
    text-align: center;
    width: 100px;
}

.income_cal_table>.col>.table-responsive>table>thead>tr>th {
    background-color: #427D9D;
    color: white;
}