.input-group-text {
    background-color: #0CCBEE !important;
    border-color: #0CCBEE;
}

.btn-login {
    background-color: #0CCBEE !important;
    border-color: #0CCBEE;
}

.register-text{
    font-size: smaller;
    margin-bottom: 0;
}

.register-text2{
    font-size: smaller;
    color: #0CCBEE;
    text-decoration: none;
}
.register-text2:hover{
    text-decoration: underline;
}