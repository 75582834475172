.row {
    margin-right: 0 !important;
}

@media (max-width: 992px) {
    .tab-detail-2 {
        justify-content: space-between;
    }

    .refer-detail {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    p {
        font-size: small;
    }

    .main-detail {
        padding-top: 10px;
        padding-left: 20px !important;
    }

    .tab-detail-1 {
        margin-right: 20px;
    }

    .tab-detail-1>.col {
        flex: 50%;
    }

    .tab-header {
        margin-right: 0;
        margin-top: 0 !important;
    }

    .tab-detail-2 {
        justify-content: center !important;
    }

    .card_detail {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 8px;
    }

    .footer {
        margin-right: 20px;
    }

    .downline-continer {
        width: 85%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    th,
    td {
        font-size: small;
    }

    .btn {
        font-size: small;
    }

    .refer-container {
        padding-left: 37px;
        margin-top: 20px;
    }

    /* คำนวณรายได้ */
    .income-cal-container {
        margin-top: 17px;
        padding-left: 30px;
    }

    .income_cal_header {
        flex-direction: column;
    }

    .income_cal_header>.col {
        text-align: center;
    }

    .filter-table {
        justify-content: center;
        margin-bottom: 10px;
    }

    .react-datepicker__input-container>input {
        font-size: smaller;
    }

    .income_cal_table>.col>.table-responsive>table>thead>tr>th {
        font-size: 0.55rem;
    }
}

@media (max-width: 481px) {
    svg {
        font-size: small;
    }
}